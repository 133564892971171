<section class="section download-area overlay-dark ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Download Text -->
                <div class="download-text text-center">
                    <h2 class="text-white">Yuju esta más cerca de ti</h2>
                    <p class="text-white my-3 d-none d-sm-block">sApp is available for all devices, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus.</p>
                    <p class="text-white my-3 d-block d-sm-none">sApp is available for all devices, consectetur adipisicing elit. Vel neque, cumque. Temporibus eligendi veniam, necessitatibus aut id labore nisi quisquam.</p>
                    <!-- Store Buttons -->
                    <!--<div class="button-group store-buttons d-flex justify-content-center">
                        <a href="#">
                            <img src="assets/img/google-play.png" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app-store.png" alt="">
                        </a>
                    </div>
                    <span class="d-inline-block text-white fw-3 font-italic mt-3">* Available on iPhone, iPad and all Android devices</span>-->
                </div>
            </div>
        </div>
    </div>
</section>